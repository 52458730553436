import { useEffect, useRef, useState } from "react";


export const Cursor = ({children}) => {
    const [ mousePos, setMousePosition ] = useState({x: -100, y: -100})
    const cRef = useRef()
    useEffect(() => {
        const updateMousePosition = (ev) => {
            if (cRef.current !== null) {
                let rect = cRef.current?.getBoundingClientRect();
                setMousePosition({ x: ev.clientX, y: ev.clientY - rect.top});
            }
        }
        window.addEventListener('mousemove', updateMousePosition);
    }, [])
    return (
        <div style={{position: 'sticky', display: 'flex', margin: 0, padding: 0}} ref={cRef}>
            <div className="cursorClass" style={{
                position: 'absolute',
                width: '30px',
                height: '30px',
                top: mousePos.y-15,
                left: mousePos.x-15
            }}>
                <img 
                    style={{
                        maxWidth: '30px',
                        maxHeight: '30px',
                        zIndex: '1',
                    }}
                    src={process.env.PUBLIC_URL + "/images/cursor.png"} alt="" />
            </div>
            { children }
        </div>
    )
}