import { AppBar, Box, Button, FormControlLabel, Switch, Toolbar } from "@mui/material"
import { createContext, useEffect, useRef, useState } from "react"

const redVersion = {
    background: "#e11d41",

    barBg: "#fff",
    barText: '#000'
}
const yellowVersion = {
    background: "#DDAA23",

    barBg: "#161618",
    barText: '#fff'
}


export const themeC = createContext()

export const NavbarNew = ({children}) => {
    const [ themeV, setThemeV ] = useState(redVersion)
    const [ checked, setChecked ] = useState(false)
    const handleChangeChecked = (e) => {setChecked(e.target.checked)}

    
    useEffect(() => {
        if (themeV === yellowVersion) {
            setThemeV(redVersion)
        } else {
            setThemeV(yellowVersion)
        }
    }, [checked])
    

    const state = {
        themeColor: themeV,
    }
    return (
        <themeC.Provider value={state}>
            <div id="themeSelected" value={themeV.background === "#DDAA23" ? "yellow" : "red"}></div>
            <Box sx={{flexGrow: 1}}>
                <AppBar position="static" className="boldF">
                    <Toolbar style={{backgroundColor: '#000', borderBottom: '1px solid #444', display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={process.env.PUBLIC_URL + '/images/new/mustang-Icon.png'} alt="Mustang" style={{width: '40px', height: '40px'}} />
                            <h2 style={{padding: '10px', letterSpacing: '2px'}}>CIA CAREERS</h2>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', flexGrow: 1}}>
                            <FormControlLabel  labelPlacement="top" control={ <Switch onChange={handleChangeChecked} checked={checked} />} label="Theme" style={{fontSize: '0.7rem'}} />
                        </div>
                        <div>
                            <Button style={{borderRadius: '0px', backgroundColor: themeV.background, color: '#fff'}}>Apply Here</Button>
                        </div>
                    </Toolbar>
                </AppBar>
            </Box>
            { children }
        </themeC.Provider>
    )
}