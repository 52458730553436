import { Button, Container, Grid, IconButton, TextField, Tooltip } from "@mui/material"

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Link } from "react-router-dom";
import FolderIcon from '@mui/icons-material/Folder';
import { useContext, useState } from "react";
import { themeC } from "./Navbar";

export const FooterNew = () => {
    const [ disabled, setDisabled ] = useState(false)
    const [ value, setValue ] = useState("")
    const changeValue = (e) => setValue(e.target.value)

    const checkValue = (e) => {
        if (e.key === 'Enter') {
            if ((value.toLowerCase() === "cia") || (value.toLowerCase() === "central intelligence agency")) {
                setValue("https://www.youtube.com/watch?v=76CaXkJr3Wk")
                setDisabled(true)
            } else {
                setValue("...Try Again")
            }
        }
    }

    const theme = useContext(themeC)

    return (
        <div>
            <style>
                {`
                @keyframes colorChange {
                    0% {
                        color: #111;
                    }
                    100% {
                        color: ${theme.themeColor.background};
                    }
                }
                `}
            </style>
            <div style={{backgroundColor: 'rgb(22 22 22)', paddingBottom:"20px", paddingTop: '10px', borderBottom: '1px solid #333'}}>
                <div className="challenge">
                    57 68 65 72 65 20 64 6F 20 79 6F 75 20 77 61 6E 74 20 74 6F 20 73 65 72 76 65 3F
                </div>

                <Container style={{paddingTop: '5px'}}>
                    <TextField 
                        disabled={disabled}
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: theme.themeColor.background,
                            },
                            '& fieldset.MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.themeColor.background,
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: theme.themeColor.background,
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: theme.themeColor.background,
                                },
                                '&:hover fieldset': {
                                  borderColor: theme.themeColor.background,
                                  borderWidth: '0.15rem',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: theme.themeColor.background,
                                },
                            },
                        }}
                        onKeyDown={checkValue}
                        value={value} onChange={changeValue} 
                        fullWidth style={{backgroundColor: '#222'}} 
                        InputLabelProps={{color: '#fff'}} inputProps={{style: {color: '#fff', borderColor: theme.themeColor.background}}} />
                </Container>
            </div>

            <div style={{minHeight: '500px', backgroundColor: '#161618', borderBottom: '1px solid #555', position: 'relative'}}>
                <div style={{position: 'absolute', top: 0, right: 0}}>
                    <img src={process.env.PUBLIC_URL + '/images/new/ciabg.png'} />                    
                </div>



                <Container style={{paddingTop: '25px'}}>
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <h3 className="gtSectraMid">Agency</h3>
                            <div>
                                { agencyLinks.map((item, i) => (
                                    <div key={i} style={{marginBottom: "20px"}}>
                                        <a href={item.href} className="footerLink">{ item.text }</a>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <h3 className="gtSectraMid">Careers</h3>
                            <div>
                                { careersLinks.map((item, i) => (
                                    <div key={i} style={{marginBottom: "20px"}}>
                                        <a href={item.href} className="footerLink">{ item.text }</a>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <h3 className="gtSectraMid">Resources</h3>                     
                            <div>
                                { resourcesLinks.map((item, i) => (
                                    <div key={i} style={{marginBottom: "20px"}}>
                                        <a href={item.href} className="footerLink">{ item.text }</a>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <h3 className="gtSectraMid">Helpful Links</h3>
                            <div>
                                { helpfulLinks.map((item, i) => (
                                    <div key={i} style={{marginBottom: "20px"}}>
                                        <a href={item.href} className="footerLink">{ item.text }</a>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <div>
                                <Button style={{
                                    marginTop: '10px',
                                    fontWeight: 'bold', letterSpacing: '2px', marginRight: '20px',
                                    backgroundColor: 'transparent', color: '#fff', border: '1px solid #666', borderRadius: '0px'}}>Report Information</Button>
                                <Button style={{
                                    marginTop: '10px',
                                fontWeight: 'bold', letterSpacing: '2px',
                                backgroundColor: 'transparent', color: '#fff', border: '1px solid #666', borderRadius: '0px'}}>Contact CIA</Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <h4 className="gtAmericaMono">Connect with CIA</h4>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>

                                { LinkItems.map((item,i) => (
                                    <Tooltip title={item.tooltip} key={i} >
                                        <div>
                                            <IconButton LinkComponent={Link} to={item.href} >
                                                { item.icon }
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                ))}

                                { /** Social Icons  */}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div style={{backgroundColor: '#161618'}}>
                <Container style={{borderLeft: '1px solid #333', borderRight: '1px solid #333',paddingTop: '20px'}}>
                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
                        { otherLinks.map((item,i) => (
                            <div key={i} style={{marginBottom: "20px"}}>
                                <a href={item.href} className="footerLink">{ item.text }</a>
                            </div>
                        ))}
                    </div>
                </Container>
            </div>
        </div>
    )
}

const agencyLinks = [
    {text: "About CIA", href: ""},
    {text: "Leadership", href: ""},
    {text: "Organization", href: ""},
    {text: "Missions and Vision", href: ""},
    {text: "CIA Museum", href: ""},
]

const careersLinks = [
    {text: "Career Opportunities", href: ""},
    {text: "Hiring Process", href: ""},
    {text: "Student Programs", href: ""},
    {text: "Accommodations", href: ""},
    {text: "MyLINK", href: ""},
]

const resourcesLinks = [
    {text: "News & Stories", href: ""},
    {text: "Center for the Study of Intelligence (CSI)", href: ""},
    {text: "The World Factbook", href: ""},
    {text: "World Leaders", href: ""},
    {text: "The Langley Files", href: ""},
    {text: "Spy Kids", href: ""},
]

const helpfulLinks = [
    {text: "Partner with CIA", href: ""},
    {text: "Prepublication Review", href: ""},
    {text: "Diversity and Inclusion", href: ""},
    {text: "Freedom of Information Act (FOIA)", href: ""},
    {text: "Frequently Asked Questions", href: ""},
    {text: "Privacy Policy", href: ""},
]

const otherLinks = [
    {text: "Search CIA.gov", href: ""},
    {text: "Site Policies", href: ""},
    {text: "Privacy", href: ""},
    {text: "No FEAR Act", href: ""},
    {text: "ECA Notice", href: ""},
    {text: "Inspector General", href: ""},
    {text: "USA.gov", href: ""},
    {text: "Sitemap", href: ""},
]

const iconStyle = {
    color: '#fff',
    width: '30px', 
    height: '30px',
}

const LinkItems = [
    {href: "https://www.instagram.com/cia", tooltip: "Instagram", icon: <InstagramIcon style={iconStyle} /> },
    {href: "https://www.facebook.com/Central.Intelligence.Agency", tooltip: "Facebook", icon: <FacebookIcon style={iconStyle}  /> },
    {href: "https://x.com/cia", tooltip: "X", icon: <XIcon style={iconStyle} />},
    {href: "https://www.linkedin.com/company/central-intelligence-agency/", tooltip: "LinkedIn", icon: <LinkedInIcon style={iconStyle} />},
    {href: "https://www.youtube.com/cia", tooltip: "Youtube", icon: <YouTubeIcon style={iconStyle}/>},
    {href: "https://t.me/s/securelycontactingcia", tooltip: "Telegram", icon: <TelegramIcon style={iconStyle} />},
    {href: "https://www.cia.gov/stories/story/the-langley-files/", tooltip: "The Langley Files", icon: <FolderIcon style={iconStyle} />},
    {href: "https://ciadotgov4sjwlzihbbgxnqg3xiyrg7so2r2o3lt5wz5ypk4sxyjstad.onion", tooltip: "Onion", icon: <img src={process.env.PUBLIC_URL + '/images/onion-white-icon.webp'} style={iconStyle} alt="Onion" />},    
]

const PodCastsOne = [
    {href: "https://open.spotify.com/episode/3AFTwPuDNxK8672wC1WpPa", tooltip: "Spotify Podcast", 
        icon: <img src={process.env.PUBLIC_URL + '/images/spotify-podcast.png'} alt="Spotify Podcast" />},
    {href: "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy50cmFuc2lzdG9yLmZtL3RoZS1sYW5nbGV5LWZpbGVzLWEtY2lhLXBvZGNhc3Q/episode/Zjc4YmQwYTEtNzFmNi00Y2UwLWEwZjAtYzU3NGY2YzZmZGNi?sa=X&ved=0CAQQkfYCahcKEwiAqY6gi6qBAxUAAAAAHQAAAAAQAQ", tooltip: "Google Podcast", 
        icon: <img src={process.env.PUBLIC_URL + '/images/google-podcast.png'} alt="Google Podcast" />},
]

const PodCastsTwo = [
    {href: "https://podcasts.apple.com/us/podcast/file-013-part-ii-the-side-of-the-argo-mission/id1645885248?i=1000627866347", tooltip: "Apple Podcast", 
        icon: <img src={process.env.PUBLIC_URL + '/images/apple-podcast.png'} alt="Apple Podcast" />},
    {href: "https://music.amazon.com/podcasts/af48b124-47cd-491c-9ef1-281886197336/episodes/7f6dd830-8388-4d55-b9a0-9d1c33eee08b/the-langley-files-cia's-podcast-file-013-part-ii---the-side-of-the-argo-mission-you%E2%80%99ve-never-heard-%E2%80%93-exfiltration", tooltip: "Amazon Podcast", 
        icon: <img src={process.env.PUBLIC_URL + '/images/amazon-podcast.png'} alt="Amazon Podcast"/>},

]