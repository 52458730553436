import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { HomePage } from './pages/HomePage';
import { NavbarNew } from './componentsnew/Navbar';
import { FooterNew } from './componentsnew/Footer';

function App() {
  // BL Built in WASM
  const [ display, setDisplay ] = useState(false)

  // Onlisted
  useEffect(() => {
    var dInt = setInterval(() => {
      const eleC = document.getElementById("r3st")
      if (eleC !== null) {
        setDisplay(true)
        clearInterval(dInt)
      }
    }, 200)
    return () => {
      clearInterval(dInt)
    }
  }, [])

  
  // Main Site
  return (
      <div>
        { display ? (
          <Fade in={true}>
            <div>
              <BrowserRouter>
                <NavbarNew>
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                  </Routes>
                  <FooterNew />
                </NavbarNew>
              </BrowserRouter>
            </div>
          </Fade>
        ) : (
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '500px'}}>
            <h2 style={{color: '#fff'}}>Loading...</h2>
          </div>
        )}
      </div>
  );
}

export default App;
