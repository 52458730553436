import { Button, Container, Grid } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { themeC } from "../componentsnew/Navbar"
import { Cursor } from "./Cursor"
import { Link } from "react-router-dom"


export const HomePage = () => {
    const colorC = useContext(themeC)
    const [ themeColor, setThemeColor ] = useState(colorC.themeColor)
    useEffect(() => {
        setThemeColor(colorC.themeColor)
    }, [colorC.themeColor])


    const borders = {
        borderLeft: '1px solid #8885', 
        borderRight: '1px solid #8885',
    }


    return (
        <div>
            { /** Header  */}
            <div style={{backgroundColor: '#161618', position: 'relative', overflowX: 'hidden', overflowY: 'hidden'}}>
                <div style={{position: 'absolute', right: '0', minWidth: '100%',
                    justifyContent: 'flex-end', display: 'flex', alignContent: 'flex-end', flexDirection: 'column', paddingTop: '40px'}}>
                    <img src={process.env.PUBLIC_URL + '/images/new/background.webp'}  alt="bg" />
                </div>
                
                <Cursor>
                    <Container style={{position: 'relative', ...borders, padding: 0}}>
                        <Grid container>
                            <div style={{
                                    position: 'absolute', 
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minHeight: '100%',
                                    minWidth: '100%',
                                    justifyContent: 'space-evenly',
                                    padding: 0,
                                    margin: 0,
                                }}>
                                <div style={{borderRight: '1px solid #8885', minHeight: '100%'}}></div>
                                <div style={{borderRight: '1px solid #8885', minHeight: '100%'}}></div>
                                <div style={{borderRight: '1px solid #8885', minHeight: '100%'}}></div>
                            </div>

                            <Grid item xs={12} md={6} style={{padding: '15px', position: 'relative'}}>
                                <h1 className="boldF" style={{fontSize: '4rem', paddingTop: '40px'}}>Our Mission Needs <span style={{color: themeColor.background}}>You</span></h1>
                                <div style={{lineHeight: '1.7rem', position: 'relative'}}>
                                    <div className="boldF">
                                        Join us and make a meaningful impact at the forefront of an ever-changing global landscape.
                                    </div>
                                    <div style={{paddingTop: '20px'}}>
                                        Make a difference that echoes far beyond borders.
                                    </div>
                                    <div style={{paddingTop: '40px'}}>
                                        <Button sx={{borderRadius: '0', border: '1px solid #eee', color: "#eee", letterSpacing: '3px', fontWeight: 'bold'}}>Learn More</Button>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item sm={12} md={6} style={{overflowX: 'hidden'}}>
                                <div style={{flex: '1', display: 'flex', overflow: 'hidden', alignItems: 'flex-end', justifyContent: 'flex-end', flexDirection: 'column'}}>
                                    <img src={process.env.PUBLIC_URL + '/images/new/woman.webp'} alt="woman" style={{width: '100%', height: 'auto'}}/>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Cursor>
            </div>

            <div style={{position: 'relative'}}>
                <div style={{backgroundColor: themeColor.barBg, color: themeColor.barText, minHeight: '100px', borderTop: '1px solid #333'}}>
                    <Container style={{...borders, zIndex: '9', minHeight: '100px', padding: '0 20px'}}>
                        <h4 style={{margin: 0, paddingTop: '20px', color: themeColor.background}}>ARE YOU SEEKING?</h4>

                        <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginTop: '20px',paddingBottom: '40px'}} className="seekingDyn">
                            <div className="gtAmericaMono wordsMobile">MISSION</div>
                            <div className="midDiv" style={{backgroundColor: themeColor.barText}}></div>
                            <div className="gtAmericaMono wordsMobile">STABILITY</div>
                            <div className="midDiv" style={{backgroundColor: themeColor.barText}}></div>
                            <div className="gtAmericaMono wordsMobile">AUTHENTICITY</div>
                            <div className="midDiv" style={{backgroundColor: themeColor.barText}}></div>
                            <div className="gtAmericaMono wordsMobile">OPPORTUNITY</div>
                        </div>

                    </Container>
                    
                </div>
            </div>

            { /** End Header */}

            { /** Internal CTA */}
            <div id="cta"></div>

            { /** From the Director */}
            <div style={{backgroundColor: '#fff', color: '#000', borderTop: '1px solid #ccc'}}>
                <Container style={{
                    display: 'flex', 
                    flexGrow:'1', 
                    padding: '0', 
                    borderLeft: '1px solid #1113', 
                    borderRight: '1px solid #1113',
                    position: 'relative'
                }}>

                    <div style={{position: 'absolute', justifyContent: 'space-evenly', display: 'flex', minHeight: '100%', minWidth: '100%'}}>
                        <div style={{borderRight: '1px solid #1113', minHeight: '100%'}}>&nbsp;</div>
                    </div>

                    <div style={{paddingTop: '20px', paddingLeft:'15px', paddingRight: '15px', position: 'relative'}}>
                        <h2 style={{paddingBottom: '10px', fontSize: '2rem'}} className="gtSectra">From the Director</h2>
                        <div style={{
                            color: '#fff',
                            backgroundColor: '#000',
                            minHeight: '150px',
                            display:"flex",
                            flexGrow: '1',
                            padding: '10px',
                            lineHeight: '3rem',
                            fontFamily: 'Times New Roman'
                        }}>
                            <div><img src={process.env.PUBLIC_URL + '/images/new/leftquote.webp'} style={{maxHeight: '50px'}} alt="&quot;" /></div>
                            <div
                                className="gtSectra quoteFont"
                                style={{padding: '10px'}}
                                >We're committed to shaping a workforce that fully reflects the diversity of American society. We cannot compete successfully on that landscape if everyone looks like me, talks like me, and thinks like me.</div>
                            <div style={{justifyContent: 'flex-end', display: 'flex', flexDirection: 'column'}}>
                                <img src={process.env.PUBLIC_URL + '/images/new/rightquote.webp'} alt="&quot;" style={{maxHeight: '50px'}}/>
                            </div>
                        </div>
                    </div>

                </Container>

                <Container style={{
                    borderLeft: '1px solid #1113', 
                    borderRight: '1px solid #1113',
                    paddingLeft: '15px',
                    paddingRight: '0px',
                }}>
                    <Grid container style={{position: 'relative', zIndex: '9'}}>
                        <Grid item xs={0} md={6}></Grid>
                        <Grid item xs={12} md={6}>
                            <div style={{border: '1px solid #888', minHeight: '50px', marginRight: '15px'}}>
                                <div className="boldF" style={{padding: '10px'}}>
                                    William J. Burns<br />
                                    <span className="lightF gtAmericaMono">Director of the CIA</span>
                                </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'flex-end',borderLeft: '1px solid #888'}}>
                                <img src={process.env.PUBLIC_URL + '/images/new/williamburns.webp'} style={{ marginTop:"-90px", maxWidth: '250px'}} />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            { /** End From the Director */}

            { /** Leverage */}
            <div style={{position: 'relative'}}>
                    
                <div style={{
                    minWidth: '100%',
                    minHeight: '420px',
                    overflowY: 'hidden',
                }}>
                    <img src={process.env.PUBLIC_URL + '/images/new/leveragebgleft.webp'} style={{position: 'absolute', right: 0, height: '100%'}} className="mobileWidth" />
                    <img src={process.env.PUBLIC_URL + '/images/new/girlbggrhalf.webp'} style={{position: 'absolute', right: 0, height: '100%', width: 'auto'}} className="mobileWidth" />
               
                    <div>
                        <Container style={{paddingTop: '50px', position:'relative'}}>
                            <div style={{
                                borderLeft: `3px solid ${themeColor.background}`
                            }}>
                                <div style={{padding: '10px 20px'}} className="boldF">Our mission is straightforward but critical:</div>
                                <div style={{padding: '0px 0 0 20px'}}>
                                    <h1 className="gtSectra">Leverage the power of information <span style={{color: themeColor.background}}>to keep our Nation safe.</span></h1>
                                </div>

                                <Grid container>
                                    <Grid item xs={12} md={5} style={{padding: '0 20px'}}>
                                        Your STEM background, your unique perspective and cultural heritage are invaluable assets in our mission to protect and advance national security. 
                                    </Grid>
                                    <Grid item xs={12} md={5} style={{padding: '0 20px'}}>
                                        Embrace the challenge, honor your roots, and become a vital part of a diverse team dedicated to safeguarding our nation's interests. 
                                    </Grid>
                                </Grid>

                                <div style={{padding: '20px'}}>
                                    <Button style={{
                                        border: '1px solid #fff9',
                                        borderRadius: '0', backgroundColor: '#000', color: '#fff', fontWeight: 'bold', letterSpacing: '2px'}} className="boldF">How We Hire</Button>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
            { /** End Leverage */}


            { /** Life at CIA */}
            <div>
                <Container style={{borderLeft: '1px solid #3339', borderRight: '1px solid #3339', display: 'flex', flexGrow: '1'}}>
                    <div style={{minWidth: '100%', paddingBottom: '40px', position: 'relative'}}>

                        <div style={{display: 'flex', justifyContent: 'space-evenly', position: 'absolute', minHeight: '100%', minWidth: '100%', zIndex: '1'}}>
                            <div style={{borderRight: '1px solid #3339'}}></div>
                            <div style={{borderRight: '1px solid #3339'}}></div>
                            <div style={{borderRight: '1px solid #3339'}}></div>
                        </div>

                        <div style={{padding: '15px', zIndex: '2', position: 'relative', marginBottom: '100px'}}>
                            <h2 className="gtSectraBig">Life at CIA</h2>
                            <div style={{backgroundColor: '#fff', width: '100%', marginTop: '40px', color: '#000'}}>
                                <div style={{display: 'flex'}} className="mobileWrap">
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <div style={{padding: '10px'}}><img src={process.env.PUBLIC_URL + '/images/new/leftquoteinv.webp'} style={{maxHeight: '50px'}} alt="&quot;" /></div>
                                            <h2 className="gtSectraBig quoteFont" style={{margin: 0, padding: '25px'}}>It's not often that the thing you love doing can also help keep your family and friends back home safe.</h2>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                justifyContent: 'flex-end',
                                                padding: '10px'
                                            }}><img src={process.env.PUBLIC_URL + '/images/new/rightquoteinv.webp'} style={{maxHeight: '50px'}} alt="&quot;" /></div>
                                        </div> 
                                        <div style={{flexGrow: '1'}}></div>
                                        <div style={{borderTop: '1px solid #000', display: 'flex', alignItems: 'center', height: '50px'}}>
                                            <div style={{padding: '20px'}} className="gtAmericaMono">Jalen, Autonomous Systems and Robotics Specialist</div>
                                        </div>
                                    </div>
                                    <div style={{backgroundColor: '#cfcfcf', overflow: 'hidden', width: '372px', paddingRight: '150px'}}>
                                        <img src={process.env.PUBLIC_URL + '/images/new/businessman.webp'} alt="law" style={{height: '100%', width: 'auto'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>


            { /** Advance Your Career */}
            <div style={{minHeight: '250px', borderTop: '1px solid #333', position: 'relative', overflowX: 'hidden'}}>

                <div style={{position: 'absolute'}}>
                    <img src={process.env.PUBLIC_URL + '/images/new/bg1.webp'} alt="bg" />
                </div>
                <Container style={{paddingTop: '60px', position: 'relative'}}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <h2 style={{color: themeColor.background, marginTop: '0', marginBottom: '10px'}} className="gtSectraBig">Advance Your Career</h2>
                            <h2 className="gtSectraBig" style={{marginTop: '0'}}>The Nation Advances With You.</h2>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px'}}>
                                <Button
                                    LinkComponent={Link} to={"https://www.cia.gov/careers/jobs?careerOpp=STEM&degree=Bachelor%27s&jobType=Full-Time&page=1"}
                                    style={{color: '#fff', borderRadius: '0', border: '1px solid #fff8'}}
                                    >Explore STEM Opportunities</Button>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            { /** AEvents */}
            <div style={{backgroundColor: '#fff', color: '#000'}}>
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={6} style={{padding: '15px'}}>
                            <div style={{padding: '20px', position: 'relative'}}>
                                <h2 className="gtSectraBig" style={{marginBottom: '0'}}>Events</h2>
                                <div>
                                    Stay updated on upcoming events, recruitment opportunities, and new gear.
                                </div>
                                <div style={{marginBottom: '30px'}}>
                                    <Button style={{
                                        border: '1px solid #3333',
                                        borderRadius: '0',
                                        color: '#000',
                                        fontWeight: 'bold',
                                        marginTop: '20px',
                                        marginRight: '20px',
                                        letterSpacing: '1px'
                                    }}>Event Gear</Button>
                                    <Button style={{
                                        border: '1px solid #3333',
                                        borderRadius: '0',
                                        color: '#000',
                                        fontWeight: 'bold',
                                        marginTop: '20px',
                                        letterSpacing: '1px'
                                    }}>Upcoming Events</Button>
                                </div>

                                <div style={{position: 'absolute', bottom: '5px', left: '5px', fontSize: '4px', paddingLeft: '20px'}}>
                                    The challenge continues <span style={{borderBottom: `1px solid ${themeColor.background}`}}>here.</span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} style={{padding: '15px', borderLeft: '1px solid #000', paddingBottom: '50px'}}>
                            <div style={{display: 'flex', justifyContent: 'center',  flexDirection: 'column', padding: '20px'}}>
                                <h2 className="gtSectraBig" style={{marginBottom: '0'}}>Test Your Skills</h2>
                                <div>
                                    See if you have what it takes. Take one of our digital challenges.
                                </div>
                                <div>
                                    <Button style={{
                                        border: '1px solid #3333',
                                        borderRadius: '0',
                                        color: '#000',
                                        fontWeight: 'bold',
                                        marginTop: '20px',
                                        letterSpacing: '1px'
                                    }}>Take the Challenge</Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            { /** Footer */}

        </div>
    )
}